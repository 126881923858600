<template lang="html">
   <form action="" class="form" @submit="onSubmit">
    <div class="form__row">
      <div class="form__field" :class="{'faded': faded}">
        <label for="name">Your Full Name</label>
        <input class="form__input" v-model="enquiry.name" required type="text" name="name" id="name">
      </div>
      <div class="errors" v-if="errors.name">{{ errors.name }}</div>
    </div>

    <div class="form__row">
      <div class="form__field" :class="{'faded': faded}">
        <label for="email">Your Telephone Number</label>
        <input class="form__input" v-model="enquiry.telephone" required type="telephone" name="telephone" id="telephone">
      </div>
      <div class="errors" v-if="errors.telephone">{{ errors.telephone }}</div>
    </div>

    <div class="form__row">
      <div class="form__field" :class="{'faded': faded}">
        <label for="email">Your Email Address</label>
        <input class="form__input" v-model="enquiry.email" required type="email" name="email" id="email">
      </div>
      <div class="errors" v-if="errors.email">{{ errors.email }}</div>
    </div>

    <div class="form__row">
      <div class="form__field" :class="{'faded': faded}">
        <label for="message">Your Message</label>
        <textarea class="form__textarea" v-model="enquiry.message" required name="message" id="message"></textarea>
      </div>
      <div class="errors" v-if="errors.message">{{ errors.message }}</div>
    </div>

    <div class="form__row">
      <button type="submit" class="button button--white" :disabled="isSending">Send Message</button>
      <transition name="success">
        <span class="success-message" v-if="success">Your message has been sent</span>
      </transition>
    </div>
  </form>
</template>

<script>
import Api from "../utils/api";

export default {
  props: [
    'category',
    'title', 
    'date',
    'location', 
    'image',
    'url'
  ],

  data: function() {
    return {
      enquiry: {
        name: '',
        telephone: '',
        email: '',
        message: '',
      },
      errors: {},
      success: false,
      isSending: false,
      faded: false
    }
  },
  
  methods: {
	  clearForm() {
			for (let field in this.enquiry) {
        this.enquiry[field] = ''
      }
      this.errors = {}      
		},

		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      Api.post('/enquiries', { enquiry: this.enquiry })
        .then((response) => {
          console.log(response);
          this.clearForm();
          this.isSending = false;
          this.success = true;
          this.faded = true;
          setTimeout(() => {
            this.success = false;
            this.faded = false;
          }, 4000);
        }).catch((e) => {
          this.isSending = false;
          console.log(e.response)
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });

		}
	}  
}
</script>

<style lang="scss">
  .errors {
    padding-top: 10px;
    color: #EF6359;
  }

  .success-message {
    padding-left: 20px;
    color: #00A4B1;
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }
</style>
const body = document.getElementsByTagName('body')[0];
const navToggle = document.querySelector('.nav-toggle');
const navToggleIcon = document.querySelector('.nav-toggle-icon');
const nav = document.querySelector('.nav');

if (navToggle) {
  navToggle.addEventListener('click', function() {  
    navToggleIcon.classList.toggle('is-active');
    body.classList.toggle('nav-is-active');
  });
}
<template lang="html">
  <div>

    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="article in articles">
        <div class="c-research-box">
          <div class="c-research-box__image">
            <img :src="article.image" v-if="article.image" :alt="article.alt" />
          </div>
          <div class="c-research-box__details">
            <p class="c-research-box__title">{{ article.name }}</p>
            <div class="c-research-box__description" v-html="article.body"></div>
            <a :href="article.url" class="button button--small button--black">View</a>
          </div>
        </div>
      </div>
    </flickity>

    <div class="controls">

      <!-- TODO: @david to tidy up css using pseudo disabled class -->
      <button @click="previous()" ref="backward" class="control control--back" :class="activeIndex == 0 ? 'is-disabled' : ''" :disabled="activeIndex == 0">
        <svg width="42px" height="42px" viewBox="0 0 42 42">
          <g id="Handover" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Individual-Issue" transform="translate(-1330.000000, -6237.000000)" stroke="#00A4B1">
              <g id="Group-7-Copy-2" transform="translate(1331.000000, 6237.875831)">
                <rect id="Rectangle-Copy-4"
                  transform="translate(20.000000, 20.000000) rotate(-180.000000) translate(-20.000000, -20.000000) " x="0"
                  y="0" width="40" height="40" rx="20"></rect>
                <g id="keyboard-arrow-right-copy"
                  transform="translate(20.000000, 20.000000) rotate(-180.000000) translate(-20.000000, -20.000000) translate(12.284963, 14.213723)"
                  stroke-linecap="round" stroke-linejoin="round">
                  <polyline id="Path" points="9.24050982 0.540298045 14.6761038 5.97589206 9.24050982 11.4114861">
                  </polyline>
                  <line x1="14.6761038" y1="5.97589206" x2="0.139698831" y2="5.97589206" id="Path"></line>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>

      <!-- TODO: @david to tidy up css using pseudo disabled class -->
      <button @click="next()" ref="forward" class="control control--forward" :class="activeIndex >= articles.length-1 ? 'is-disabled' : ''" :disabled="activeIndex >= articles.length-1">
        <svg width="42px" height="42px" viewBox="0 0 42 42">
          <g id="Handover" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Individual-Issue" transform="translate(-1430.000000, -6237.000000)" stroke="#00A4B1">
              <g id="Group-8-Copy" transform="translate(1431.000000, 6237.875831)">
                <rect id="Rectangle" x="0" y="0" width="40" height="40" rx="20"></rect>
                <g id="keyboard-arrow-right" transform="translate(12.284963, 14.213723)" stroke-linecap="round"
                  stroke-linejoin="round">
                  <polyline id="Path" points="9.24050982 0.540298045 14.6761038 5.97589206 9.24050982 11.4114861">
                  </polyline>
                  <line x1="14.6761038" y1="5.97589206" x2="0.139698831" y2="5.97589206" id="Path"></line>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>

    </div>

  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import imagesLoaded from 'vue-images-loaded';

export default {
  directives: {
    imagesLoaded
  },
  props: [
    "articles",
  ],
  components: {
    Flickity
  },
  data: function() {
    return {
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        adaptiveHeight: true,
        draggable: false,
        imagesLoaded: true
      },
      activeIndex: 0,
    }
  },
  methods: {
    next() {
      this.$refs.flickity.next();
      this.activeIndex++
    },
    
    previous() {
      this.$refs.flickity.previous();
      this.activeIndex--
    },
  },
}
</script>

<style lang="scss" scoped>
  .carousel-cell {
    width: 100%;
    min-height: 300px;
    margin-right: 40px;

    @media screen and (max-width: 999px) {
      min-height: unset;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
  }

  .control {
    display: inline-block;
    width: 42px;
    height: 42px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: opacity .3s;

    &.is-disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &--back { 
      margin-right: 60px;
    }
  }
</style>
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

import '../javascript/test'
import '../javascript/utils/google-tracker'

import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import Flickity from 'vue-flickity';

Vue.use(require('vue-moment'));

import EventSlider from '../javascript/components/event-slider.vue';
import ResearchSlider from '../javascript/components/research-slider.vue';
import ArticleGrid from '../javascript/components/article-grid.vue';
import ArticleCard from '../javascript/components/article-card.vue';
import EventCard from '../javascript/components/event-card.vue';
import ContactForm from '../javascript/components/contact-form.vue';
import Newsletter from '../javascript/components/newsletter.vue';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '[data-behavior="vue"]',
    components: {
      EventSlider,
      ResearchSlider,
      ArticleGrid,
      ArticleCard,
      EventCard,
      Flickity,
      ContactForm,
      Newsletter,
    }
  });
});
<template lang="html">
  <section class="event-slider">
    <div class="event-slider__background" :style="{backgroundImage: `url('${activeEvent.background_image}')`}"></div>
    <div class="outer-wrapper">
      <div class="event-slider__container">
        <div class="event-slider__left">
          <div class="event-slider__description">
            <h2 class="event-slider__description__headline">{{ title }}</h2>

            <div class="event-slider__description__body" v-html="body"></div>
          </div>
        </div>

        <div class="event-slider__right">
          <flickity ref="flickity" :options="flickityOptions">
            <div class="carousel-cell" v-for="event in events">
              <event-card
                :date="new Date(event.date)"
                :name="event.name"
                :description="event.body"
                :location="event.location"
                :url="event.url" />
            </div>
          </flickity>

          <div class="controls">

            <!-- TODO: @david to tidy up css using pseudo disabled class -->
            <button @click="previous()" ref="backward" class="control control--back" :class="activeIndex == 0 ? 'is-disabled' : ''" :disabled="activeIndex == 0">
              <svg width="42px" height="42px" viewBox="0 0 42 42">
                <g id="Handover" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Individual-Issue" transform="translate(-1330.000000, -6237.000000)" stroke="#00A4B1">
                    <g id="Group-7-Copy-2" transform="translate(1331.000000, 6237.875831)">
                      <rect id="Rectangle-Copy-4"
                        transform="translate(20.000000, 20.000000) rotate(-180.000000) translate(-20.000000, -20.000000) " x="0"
                        y="0" width="40" height="40" rx="20"></rect>
                      <g id="keyboard-arrow-right-copy"
                        transform="translate(20.000000, 20.000000) rotate(-180.000000) translate(-20.000000, -20.000000) translate(12.284963, 14.213723)"
                        stroke-linecap="round" stroke-linejoin="round">
                        <polyline id="Path" points="9.24050982 0.540298045 14.6761038 5.97589206 9.24050982 11.4114861">
                        </polyline>
                        <line x1="14.6761038" y1="5.97589206" x2="0.139698831" y2="5.97589206" id="Path"></line>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>

            <!-- TODO: @david to tidy up css using pseudo disabled class -->
            <button @click="next()" ref="forward" class="control control--forward" :class="activeIndex >= events.length-1 ? 'is-disabled' : ''" :disabled="activeIndex >= events.length-1">
              <svg width="42px" height="42px" viewBox="0 0 42 42">
                <g id="Handover" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Individual-Issue" transform="translate(-1430.000000, -6237.000000)" stroke="#00A4B1">
                    <g id="Group-8-Copy" transform="translate(1431.000000, 6237.875831)">
                      <rect id="Rectangle" x="0" y="0" width="40" height="40" rx="20"></rect>
                      <g id="keyboard-arrow-right" transform="translate(12.284963, 14.213723)" stroke-linecap="round"
                        stroke-linejoin="round">
                        <polyline id="Path" points="9.24050982 0.540298045 14.6761038 5.97589206 9.24050982 11.4114861">
                        </polyline>
                        <line x1="14.6761038" y1="5.97589206" x2="0.139698831" y2="5.97589206" id="Path"></line>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Flickity from 'vue-flickity';
import EventCard from './event-card';

export default {
  components: {
    Flickity,
    EventCard
  },

  props: [
    'title',
    'body', 
    'events',
  ],

  data: function() {
    return {
      activeIndex: 0,
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        adaptiveHeight: true,
        draggable: false,
        imagesLoaded: true
      }
    }
  },

computed: {
    activeEvent: function() {
      return this.events[this.activeIndex]
    }
  },

  methods: {
    next() {
      this.$refs.flickity.next();
      this.activeIndex++
    },
    
    previous() {
      this.$refs.flickity.previous();
      this.activeIndex--
    },
  }
}
</script>

<style lang="scss">
  .carousel-cell {
    width: 100%;
    margin-right: 40px;
  }

  .event-slider {
    height: 975px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 768px) {
      height: auto;
    }

    .outer-wrapper {
      height: 100%;
    }

    &__background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 1;
      opacity: 0.4;
      filter: grayscale(100%);      
      // Temporary static bg image - will be replaced by AWS asset url
      background-image: url('../assets/images/first-image.png');
      transition: background-image 0.5s linear;
    }

    &__container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 2;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
      }
    }

    &__left {
      flex: 1;
      padding-right: 25px;

      @media screen and (max-width: 768px) {
        padding-right: 0;
        margin-bottom: 48px;
      }
    }

    &__right {
      flex: 1;
      display: block;
      position: relative;
    }

    &__description {
      max-width: 480px;

      &__headline {   
        font-family: "Avenir-Next-Bold", Arial, sans-serif;
        font-weight: bold;    
        font-size: 1em;
        color: #06262D;
        line-height: 1.9375;
        margin-bottom: 18px;
      }

      &__body {
        font-family: 'Source Serif Pro',
        Georgia,
        serif;
        font-size: 1em;
        color: #0B0B0B;
        line-height: 2.18;

        p {
          font-family: 'Source Serif Pro', Georgia, serif;
          font-size: 1em;
          color: #0B0B0B;
          line-height: 2.18;
        }
      }
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
  }

  .control {
    display: inline-block;
    width: 42px;
    height: 42px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: opacity .3s;

    &.is-disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &--back { 
      margin-right: 60px;
    }
  }
</style>
<template lang="html">
  <div class="article-card">
    <a :href="url" aria-label="Article link">
      <div class="article-card__image event" v-if="articleType == 'event'">
        <div class="article-card__image__map" :style="`background-image: url('${image}')`"></div>
        <i class="icon icon--location"></i>
      </div>
      
      <div class="article-card__image research" v-else-if="articleType == 'research'">
        <img :src="image" :alt="imageAlt" aria-label="Research image" />
      </div>
     
      <img class="article-card__image" :src="image" :alt="imageAlt" v-else />
    </a>

    <div class="article-card__category c-category">
      <span class="c-category__title">{{ articleType }}</span>
    </div>

    <a :href="url" class="article-card__title">{{ name }}</a>
    
    <div v-if="articleType != 'event'">
      <p class="article-card__date">{{ publishedAt | moment("Do MMMM, YYYY") }}</p>
    </div>
    <div v-else class="article-card__event">
      <p class="article-card__event__location">&#64; {{ eventLocation }}</p>
      <p class="article-card__event__date">on {{ eventDate | moment("Do MMMM, YYYY") }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    'articleType',
    'name', 
    'publishedAt',
    'eventLocation', 
    'eventDate', 
    'image',
    'imageAlt',
    'url'
  ]
}
</script>

<style lang="scss">
  .article-card {
    position: relative;
    background-color: #FFF;
    padding: 20px 20px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 520px) {
      border-bottom: 0;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: 20px;
        background-color: #F7F7F7;
      }
    }

    &:hover {
      .article-card__title {
        color: #00A4B1;
      }
    }

    &__image {
      margin-bottom: 18px;
      width: 100%;
      overflow: hidden;

      &__map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center;
        border: 1px solid #E3E3E3;
        border-radius: 100%;
        filter: grayscale(100%);
        transition: all 0.5s;
        &:hover {
          filter: grayscale(0%);
        }
      }
    }

    &__image {
      &.event {
        position: relative;
        height: 0;
        padding-top: 100%;
        overflow: hidden;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 8px);
        }
      }

      &.research {
        background: #F0F0F0;
        height: 100%;
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          max-width: 65%;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.26), 
                      0px 7px 19px 0px rgba(0, 0, 0, 0.26);
        }

        @media screen and (max-width: 375px) {
          min-height: 380px;
        }
      }
    }

    &__category {
      margin-bottom: 12px;
    }

    &__title {
      display: block;
      font-family: 'Sharp Sans Extrabold', 'Futura', sans-serif;
      font-size: 0.875em;
      color: #000000;
      line-height: 2.2;
      margin-bottom: 12px;
      transition: color .35s;
    }

    &__date {
      font-family: 'AvenirNext-Medium', Arial, sans-serif;
      font-weight: 500;
      font-size: 0.875em;
      color: #595959;
      line-height: 2.2;
    }

    &__event {
      p {
        font-family: 'AvenirNext-Medium', Arial, serif;
        font-weight: 500;
        font-size: 0.875em;
        color: #F3B120;
        line-height: 1.6;
      }
    }
  }
</style>
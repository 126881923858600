<template lang="html">
  <div class="articles">
    <div class="articles-filter">
      <span class="showing">Showing</span>
      
      <v-select 
        :options="postTypeFilterOptions"
        placeholder="Choose a Post Type"
        :clearable="false"
        :searchable="false"
        v-on:search:focus="toggleFade()"
        v-on:search:blur="toggleFade()"
        v-show="false"
        v-model="selectedArticleType"
        @input="filter"
        :class="{'active': selectedArticleType.length != 0}"
        ></v-select>

      <v-select 
        :options="issuesFilterOptions"
        placeholder="Choose a Theme"
        :clearable="false"
        :searchable="false"
        v-on:search:focus="toggleFade()"
        v-on:search:blur="toggleFade()"
        v-model="selectedCategory"
        @input="filter"
        :class="{'active': selectedCategory.length != 0}"
        ></v-select>
    </div>

    <div class="articles-grid" :class="{'faded': filterActive}">
      <div class="outer-wrapper">
        <div class="grid grid--articles">
          <p v-if="articles && articles.length == 0">No articles available</p>

          <div class="grid-item" v-for="article in articles">
            <article-card
              :key="article.id"
              :articleType="article.article_type"
              :name="article.name"
              :publishedAt="new Date(article.published_at)"
              :eventLocation="article.event_location"
              :eventDate="new Date(article.event_date)"
              :image="article.image"
              :imageAlt="article.imageAlt"
              :url="article.url"
            />
          </div>
        </div>

        <div class="articles-grid__load-more" v-if="hasMore">
          <button type="button" class="button button--white" @click="fetchMore" aria-label="Show More">Show More</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleCard from './article-card';
import Api from "../utils/api";

const Capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.substr(1)
}

export default {
  components: {
    ArticleCard
  },

  props: [
    "articleTypes",
    "articleType",
    "categories",
    "resourceForChange",
  ],


  computed: {
    postTypeFilterOptions: function() {
      return [
        {label: 'All Posts', value: ''},
        ...this.articleTypes.map(a => ({label: Capitalize(a), value: a}))
      ]        
    },
    issuesFilterOptions: function() {
      return [
        {label: 'All Themes', value: ''},
        ...this.categories.map(c => ({label: c.name, value: c.id}))
      ]
    },
  },

  data() {
    return {
      articles: [],
      filterActive: false,
      selectedArticleType: this.articleType ? {label: Capitalize(this.articleType), value: this.articleType} : '',
      selectedCategory: '',
      offset: 0,
      limit: 12,
      hasMore: false,
    }
  },
  methods: {
    toggleFade() {
      if (this.filterActive == true) {
        this.filterActive = false;
      } else {
        this.filterActive = true;
      }
    },

    filter() {
      this.loading = true
      this.offset = 0
      this.hasMore = false
      Api.get('/articles', { params: { 
        article_type: this.selectedArticleType.value, 
        category: this.selectedCategory.value,
        resource_for_change: this.resourceForChange,
        offset: 0,
        limit: this.limit
      }})
        .then((response) => {
          this.articles = response.data.articles
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetchMore() {
      this.offset++
      Api.get('/articles', { params: { 
        article_type: this.selectedArticleType.value, 
        category: this.selectedCategory.value,
        resource_for_change: this.resourceForChange,
        offset: this.offset,
        limit: this.limit
      }})
        .then((response) => {
          this.articles = [...this.articles, ...response.data.articles]
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });      
    }
  },

  mounted() {
    this.filter();
  }
}
</script>

<style lang="scss">
  .articles-filter {
    background: #FBFBFB;
    padding: 60px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      padding: 48px 20px;
    }

    @media screen and (max-width: 470px) {
      align-items: flex-start;
    }

    .showing {
      display: inline-block;
      color: #000000;
      line-height: 2;
      margin-right: 42px;

      @media screen and (max-width: 700px) {
        margin-right: 0;
        margin-bottom: 28px; 
      }
    }
  }
  
  .articles-grid {
    padding: 100px 0;
    transition: opacity .4s;

    .outer-wrapper {
      width: 100%;
    }

    @media screen and (max-width: 559px) {
      padding: 0;
      .outer-wrapper {
        padding: 0;
      }
    }

    &.faded {
      opacity: 0.2;
    }

    &__load-more {
      padding: 80px 0 0;
      text-align: center;

      @media screen and (max-width: 520px) {
        padding-top: 60px;
      }
    }
  }
</style>
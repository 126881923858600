<template>
  <mailchimp-subscribe
    url="https://thesocialchangeinitiative.us14.list-manage.com/subscribe/post-json"
    user-id="4e4ccf0d496025d4ae2ddb04e"
    list-id="11711d7d6e"
    @error="onError"
    @success="onSuccess"
  >
    <template v-slot="{ subscribe, setField, error, success, loading }">
      <form @submit.prevent="subscribe" class="newsletter-signup__form">
        <input type="text" name="FNAME" placeholder="First name" @input="setField('firstName', $event.target.value)">
        <input type="text" name="LNAME" placeholder="Last name" @input="setField('lastName', $event.target.value)">
        <input type="email" placeholder="Email address" name="email" @input="setField('email', $event.target.value)">
        <div v-if="error" class="error" v-html="error"></div>
        <div v-if="success" class="success">You've been subscribed!</div>
        <div v-if="loading" class="loading">Loading…</div>
        <button type="submit" class="button button--blue">Sign up</button>
      </form>
    </template>
  </mailchimp-subscribe>
</template>

<script>
import MailchimpSubscribe from './mailchimp-subscribe'

export default {
  components: {
    MailchimpSubscribe,
  },
  methods: {
    onError() {
      // handle error
    },
    onSuccess() {
      // handle success
    },
  },
}
</script>


<style lang="scss">
  .error, .success, .loading  {
    padding-top: 20px;
    padding-right: 40px;
    color: #fff;

    a {
      color: #fff;
      font-weight: bold;
      text-decoration: underline;
    }
  }

</style>
<template>
  <div class="event-card">
    <div class="event-card__header">
      <div class="event-card__date">
        <span class="day">{{ date.getDate() }}</span>

        <span class="rest">{{ date | moment("MMMM, YYYY") }}</span>
      </div>

      <a :href="url" class="button button--small button--black" aria-label="View Article">View Article</a>
    </div>

    <div class="event-card__body">
      <h3 class="event-card__title">{{ name }}</h3>
      <p class="event-card__description" v-html="description"></p>

      <div class="event-card__location">
        <i class="location-icon"></i>
        <span class="location-name">{{ location }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'date',
    'name',
    'description',
    'location',
    'url'
  ]
}
</script>

<style lang="scss" scoped>
  .event-card {
    display: block;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    margin-bottom: 20px;

    &__header {
      padding: 26px;
      border-bottom: 1px solid #E3E3E3;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 425px) {
        padding: 12px;
      }
    }

    &__body {
      padding: 20px 28px 26px;

      @media screen and (max-width: 425px) {
        padding: 20px 12px 26px;
      }
    }

    &__title {
      font-family: 'AvenirNext-Medium', Arial, sans-serif;
      font-weight: 500;
      font-size: 1em;
      color: #000000;
      line-height: 1.9375;
      margin-bottom: 14px;
    }

    &__description {
      font-family: 'SourceSerifPro-Regular', serif;
      font-size: 0.875em;
      color: #000000;
      line-height: 2.14;
      margin-bottom: 14px;
    }

    &__location {
      display: flex;
      align-items: center;

      .location-icon {
        display: inline-block;
        width: 11px;
        height: 16px;
        background-image: url('../assets/images/icon--location.svg');
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 12px;
      }

      .location-name {
        font-family: 'Avenir Next Medium', Arial, sans-serif;
        font-size: 0.9375em;
        color: #00A4B1;
        line-height: 2.06;
      }
    }

    &__date {
      .day {
        display: inline-block;
        background-color: #EF6359;
        padding: 6px 12px;
        font-family: 'AvenirNext-Medium', Arial, sans-serif;
        font-size: 1em;
        color: #FFFFFF;
        line-height: 1.9375;
        margin-right: 12px;

        @media screen and (max-width: 425px) {
          margin-right: 4px;
        }
      }

      .rest {
        font-family: AvenirNext-Medium;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        line-height: 31px;
      }
    }
  }
</style>